import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import Image from '../../../components/image'
import { SectionWrap, ArtworkWrap, ArtworkItem } from './artwork.stc'
import Swiper from '../../../components/shared/swiper'

const Artwork = (props,{ headingCSS, textCSS }) => {
  const characterData = useStaticQuery(graphql `
  query ArtworkContentQuery {
      allCharacterJson {
                        nodes {
                                id
                                title
                                category
                                desc
                                actor
                                link
                                tags
                                site
                                image {
                                        childImageSharp {
                                            fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                                                ...GatsbyImageSharpFluid_withWebp
                                                presentationHeight
                                                presentationWidth
                                            }
                                        }
                                    }
                    }
              }
                }
`)
  const characters = characterData.allCharacterJson.nodes
  const { sliderSettings, sliderStyle, sectionTitleCSS } = props
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Heading {...headingCSS}> Follow our Voice Actors on Social Media:</Heading>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
          
              <Swiper {...sliderStyle}  settings={ sliderSettings}>
              {characters && characters.map((character, i) => (
                <ArtworkItem key={`instagram-${i}`}>
                  {character && character.image && character.image.childImageSharp && (
                    <div>
                    <Image fluid={character.image.childImageSharp.fluid} alt="Instagram" />
                    <Text >{character.actor}</Text> 
                    <span className="text-white">as {character.title}</span>
  
                    </div>
                  )}
                  {character.link && <a href={character.link} target="_blank" rel="noopener noreferrer">Actor Link</a>}
                </ArtworkItem>
              ))}
              </Swiper >
      

          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}


Artwork.defaultProps = {
  headingCSS: {
    as: 'h5',
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: 1,
    mb: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textCSS: {
    as: 'span',
    pl: '4px'
  },
  sliderSettings: {
		slidesPerView: 5,
		spaceBetween: 30,
		customArrows: true,
		autoplay: {
			delay: 5000
		},
		breakpoints: {
			1200: {
				slidesPerView: 5,
			},
			768: {
				slidesPerView: 3,
			},
			320: {
				slidesPerView: 2
			}
		}
	},

}

export default Artwork
